import React from "react";
import SystemPromptForm from "./SystemPromptForm";

function SystemPromptPage() {
  return (
    <div>
      <SystemPromptForm />
    </div>
  );
}

export default SystemPromptPage;
